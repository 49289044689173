@import './fonts.scss';
@import './variables.scss';
@import './mixins.scss';
@import './globals.scss';

// ==== Библиотеки ====
@import '~normalize.css/normalize.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '~react-modal-video/scss/modal-video.scss';

// ==== Общие компоненты ====
@import '@generic/Button/Button.scss';
@import '@generic/Title/Title.scss';
@import '@generic/Form/Form.scss';
@import '@generic/Input/Input.scss';
@import '@generic/Textarea/Textarea.scss';
@import '@generic/Select/Select.scss';
@import '@generic/Video/Video.scss';
@import '@generic/SliderArrow/SliderArrow.scss';
@import '@generic/CustomSlider/CustomSlider.scss';
@import '@generic/DoubleSlider/DoubleSlider.scss';
@import '@generic/Reviews/Reviews.scss';
@import '@generic/RecordButton/RecordButton.scss';
@import '@generic/FeaturesBlocks/FeaturesBlocks.scss';
@import '@generic/Card/Card.scss';
@import '@generic/Preview/Preview.scss';
@import '@generic/SocialLink/SocialLink.scss';
@import '@generic/SocialIcon/SocialIcon.scss';

// ==== Компоненты ====
@import '@components/Header/Header.scss';
@import '@components/Menu/Menu.scss';
@import '@components/Promo/Promo.scss';
@import '@components/Record/Record.scss';
@import '@components/Features/Features.scss';
@import '@components/Founder/Founder.scss';
@import '@components/Why/Why.scss';
@import '@components/Worker/Worker.scss';
@import '@components/Athlete/Athlete.scss';
@import '@components/Schedule/Schedule.scss';
@import '@components/Location/Location.scss';
@import '@components/CampLocation/CampLocation.scss';
@import '@components/RedyarHome/RedyarHome.scss';
@import '@components/Footer/Footer.scss';
@import '@components/Merch/Merch.scss';
@import '@components/Competition/Competition.scss';
@import '@components/About/About.scss';
@import '@components/DailyRoutine/DailyRoutine.scss';
@import '@components/Besides/Besides.scss';
@import '@components/VideoReport/VideoReport';
@import '@components/FAQ/FAQ.scss';
@import '@components/AwardsTabs/AwardsTabs.scss';
@import '@components/OurAthlete/OurAthlete.scss';
@import '@components/Cards/Cards.scss';
@import '@components/Discount/Discount.scss';
@import '@components/Categories/Categories.scss';
@import '@components/PreviewList/PreviewList.scss';
@import '@components/Search/Search.scss';
@import '@components/Blog/Blog.scss';
@import '@components/Back/Back.scss';
@import '@components/Article/Article.scss';
@import '@components/ReadMore/ReadMore.scss';
@import '@components/Filters/Filters.scss';
@import '@components/Goods/Goods.scss';
@import '@components/CartButton/CartButton.scss';
@import '@components/MyMap/MyMap.scss';
@import '@components/Modal/Modal.scss';
@import '@components/Success/Success.scss';
@import '@components/AthletesCards/AthletesCards.scss';
@import '@components/AthleteCard/AthleteCard.scss';

// ==== Страницы ====
@import '@pages/Shop/Shop.scss';
