.awards {
  @include background-before('../../assets/img/bg-2.jpg', -30%, 200%);
  background-position: center;

  .flex {
    flex-wrap: wrap;
  }
  button {
    cursor: pointer;
  }
  button.active .title,
  button.active:hover .title {
    color: $orange;
  }
  button:hover .title {
    color: #fff;
  }
  button:not(:last-of-type) {
    margin-right: 3em;
  }
  .title {
    // color: $l-gray-2;
    color: #fff;
    line-height: 130%;
    transition: ease 0.15s;
  }
}
.awards__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1.5em;

  li {
    padding: 10% 10%;
    background-color: $l-gray;

    * {
      display: block;
    }
  }
}
.awards__img {
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 50px;
}
.awards__title {
  margin-bottom: 15px;
  @include big-text;
  line-height: 120%;
  color: #f2f2f2;
}
.awards__text {
  @include text;
  line-height: 120%;
  color: $l-gray-2;
}

@media (max-width: 1366px) {
}
