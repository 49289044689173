.search {
  width: 50%;

  input {
    padding: 15px 30px;
    width: 100%;
    border: 1px solid $gray;
    border-radius: 40px;
    outline: none;
    background: url('../../assets/icons/search.svg') 96% no-repeat $gray;

    &:focus {
      border: 1px solid $orange;
    }
  }
}
@media (max-width: 1024px) {
  .search {
    width: 100%;
  }
}
