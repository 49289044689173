.features {
  background-image: url('../../assets/img/dust-2.png');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 0 150%;
}
.features__list {
  li {
    position: relative;
    padding-top: 90px;
    padding-bottom: 90px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0;
      transition: ease 0.5s;
      z-index: -1;
    }
    &:hover img {
      opacity: 1;
      transform: scale(1.02);
    }
    p {
      @include text;
      display: flex;
      span {
        display: inline-block;
        &:not(:last-of-type) {
          margin-right: 30px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .features__list li {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media (max-width: 500px) {
  .features__list li {
    padding-top: 70px;
    padding-bottom: 70px;

    .subtitle {
      margin-bottom: 15px;
    }
    p {
      justify-content: space-between;
    }
    span:last-of-type {
      text-align: right;
    }
  }
}
