.button {
  @include text;
  padding: 13px 80px;
  height: fit-content;
  text-align: center;
  border: 1px solid $orange;
  border-radius: 40px;
  background-color: $orange;
  cursor: pointer;
  transition: ease 0.25s;

  &:hover {
    background-color: #fff;
    color: $orange;
    border: 1px solid $orange;
  }
  &--gray {
    color: $orange;
    background-color: $l-gray;
    border: 1px solid $l-gray;
  }
  &:focus {
    outline: none;
    border-color: #fff;
  }
}

@media (max-width: 500px) {
  .button {
    display: inline-block;
    width: 100% !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}
