.besides {
  height: 200%;
  z-index: 1;
  // @include background-before('../../assets/img/bg-1.jpg', -70%, 200%);
}
.besides__flex {
  display: flex;
  flex-direction: column;

  .title {
    margin: 0;
    width: 50%;

    span:nth-of-type(2) {
      margin-left: -2%;
    }
  }
}
.besides__text {
  width: 50%;
  padding-left: 10px;
  margin-left: auto;
  @include medium-text;
}
.besides__pastime {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 180px;
  // grid-template-rows: repeat(7, 1fr);
  grid-column-gap: 30px;

  li {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  li:nth-of-type(1) {
    grid-column-start: 1;
  }
  li:nth-of-type(2) {
    top: 60px;
    grid-column-start: 2;
  }
  li:nth-of-type(3) {
    top: 120px;
    grid-column-start: 3;
  }
  li:nth-of-type(4) {
    top: 180px;
    grid-column-start: 4;
  }
  .routine__image {
    width: 300px;
    height: 300px;
    img {
      object-fit: cover;
      width: 100%;
    }
  }
  b {
    margin-bottom: 15px;
    display: inline-block;
    @include big-text;
    line-height: 140%;
    text-transform: uppercase;
  }
  p {
    margin: 0;
    @include text;
    font-size: 18px;
  }
}
.besides__text {
  @include text;
}

@media (max-width: 1600px) {
  .besides {
    margin-bottom: 3em;
  }
  .besides__pastime {
    grid-column-gap: 20px;

    li:nth-of-type(1) {
      // grid-row: 1 / 4;
      grid-column-start: 1;
    }
    li:nth-of-type(2) {
      // grid-row: 2 / 5;
      grid-column-start: 2;
    }
    li:nth-of-type(3) {
      // grid-row: 3 / 6;
      grid-column-start: 3;
    }
    li:nth-of-type(4) {
      // grid-row: 4 / 7;
      grid-column-start: 4;
    }
  }
}
@media (max-width: 1366px) {
  .besides {
    margin-bottom: 1em;
  }
}
@media (max-width: 1024px) {
  .besides__pastime {
    grid-template-columns: repeat(2, 1fr);

    li:nth-of-type(1) {
      grid-column-start: 1;
    }
    li:nth-of-type(2) {
      // grid-row: 3 / 23;
      grid-column-start: 2;
    }
    li:nth-of-type(3) {
      // grid-row: 22 / 42;
      top: 60px;
      grid-column-start: 1;
    }
    li:nth-of-type(4) {
      // grid-row: 24 / 44;
      top: 120px;
      grid-column-start: 2;
    }
  }
}
@media (max-width: 500px) {
  .besides__pastime {
    display: block;
    margin-bottom: 0;

    li {
      top: 0 !important;
    }
    li:not(:last-of-type) {
      margin-bottom: 3em;
    }
  }
  .besides__flex {
    .title {
      margin-bottom: 1em;
      width: 100%;

      span:nth-of-type(2) {
        margin-left: 20%;
      }
      span:nth-of-type(3) {
        margin-left: 60%;
      }
    }
  }
  .besides__text {
    margin-bottom: 20px;
    width: 100%;
  }
}
