.back {
  @include big-text;
  line-height: 150%;
  color: #f2f2f2;
  cursor: pointer;
  transition: ease 0.15s;

  object {
    margin-right: 1em;
  }
  &:hover {
    color: $orange;
  }
}
