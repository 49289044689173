.redyar-home {
  padding: 100px 0;
  background-image: url('../../assets/img/redyar-home.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
.redyar-home__content {
  width: 50%;

  p {
    @include medium-text;
    margin-bottom: 35px;
  }
  ul {
    display: flex;
    justify-content: space-between;
    margin-bottom: 75px;

    li {
      position: relative;
      @include medium-text;
      line-height: 120%;
      color: #f2f2f2;
      transition: ease 0.15s;
    }
    li::after {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: #fff;
    }
  }
  .button {
    @include text;
    padding-top: 17px;
    padding-bottom: 17px;
  }
}

@media (max-width: 1600px) {
  .redyar-home__content {
    width: 60%;
  }
}
@media (max-width: 1024px) {
  .redyar-home {
    padding: 0;
    background-attachment: unset;
  }
  .redyar-home__content {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .redyar-home {
    background-position: 70%;
  }
  .redyar-home__content ul {
    flex-direction: column;

    li {
      width: fit-content;
    }
    li:not(:last-of-type) {
      margin-bottom: 2em;
    }
  }
}
