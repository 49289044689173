.double-slider__wrapper {
  padding-bottom: 0;
}
.double-slider {
  .slick-slide {
    margin: 0 5px;

    img {
      height: 100%;
      object-fit: cover;
      max-height: 340px;
    }
  }
  .title {
    margin-bottom: 0;
  }
}
.double-slider__component:nth-last-child(2) {
  margin-bottom: 5px;
}
.double-slider__component:nth-last-child(1) {
  margin-bottom: 30px;
}
.double-slider__arrows {
  display: flex;
  align-items: center;

  button:first-of-type {
    margin-right: 1em;
  }
}

@media (max-width: 768px) {
  .double-slider {
    .slick-slide {
      img {
        max-height: 270px;
      }
    }
  }
}
@media (max-width: 500px) {
  .double-slider {
    position: relative;
    padding-bottom: 45px;

    .slick-slide {
      margin: 0;
      max-height: 38vh;

      img {
        object-position: center;
        max-height: 240px;
      }
    }
    .slick-track:first-child {
      margin-bottom: 5px !important;
    }
  }
  .double-slider .wrapper > .flex {
    display: block !important;
    margin-bottom: 20px !important;

    .title {
      width: 100%;
    }
  }
  .double-slider__arrows {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    justify-content: space-between !important;
    z-index: 100;
  }
  .double-slider__component {
    margin-bottom: 0 !important;
  }
}
