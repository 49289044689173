.our-athlete {
  padding: 35px;
  margin-right: 1.5em;
  background-color: $gray;
}
.our-athlete__photo {
  margin: 0 15px;
  flex-shrink: 0;
  width: 135px;
  height: 135px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
  background-color: $l-gray-2;
}
.our-athlete__name {
  display: block;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;

  color: #f2f2f2;
}
.our-athlete__rank {
  color: $orange;
}
.our-athlete__achivements {
  column-count: 2;
  column-width: 50%;

  li {
    position: relative;
    padding-left: 15px;
    color: $l-gray-2;
  }
  li::before {
    position: absolute;
    content: '•';
    left: 0;
    color: $orange;
  }
  li:not(:last-child) {
    margin-bottom: 10px;
  }
}

@media (max-width: 1280px) {
  .our-athlete {
    padding: 25px;
    margin-right: 1em;
  }
}
@media (max-width: 1100px) {
  .our-athlete {
    padding: 35px;
    margin: 0 2px;
  }
}
@media (max-width: 500px) {
  .our-athlete {
    padding: 25px;
    margin: 0 2px;
  }
}
