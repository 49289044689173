.routine {
  &::before {
    position: absolute;
    content: '';
    top: -20%;
    left: 0;
    width: 100%;
    height: 130%;
    background-image: url('../../assets/img/bg-1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    z-index: -1;
  }
}
.routine__container {
  display: flex;
}
.routine__schedule {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  li {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 100px;
    border-top: 2px solid $gray;
    box-sizing: border-box;
  }
  li:last-child {
    border-bottom: 2px solid $gray;
  }
  div {
    flex-grow: 1;
  }
  .schedule__time {
    margin: 0;
    margin-right: 15px;
    width: 20%;
  }
}
.routine__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1279px) {
  .routine__schedule li {
    padding: 0 50px;
  }
}
@media (max-width: 1023px) {
  .routine__schedule li {
    padding: 0 30px;

    .schedule__time {
      flex-shrink: 0;
      width: 30%;
    }
  }
}
@media (max-width: 767px) {
  .routine__container {
    display: block;
    padding: 0 40px;
  }
  .routine__schedule li {
    padding: 30px 0;
    display: block;

    .schedule__time {
      margin-bottom: 15px;
    }
  }
  .routine__image img {
    max-height: 400px;
  }
}
@media (max-width: 500px) {
  .routine__container {
    padding: 0 20px;
  }
}
