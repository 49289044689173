.social {
  display: flex;
  align-items: center;
  position: relative;
  width: 50px;
  height: 50px;
  justify-content: center;
  border-radius: 50%;
  background-color: $orange;
  transition: ease 0.25s;

  &:hover {
    background-color: #fff;

    .soical-icon {
      background-color: $orange;
    }
  }
}

@media (max-width: 1366px) {
  .social {
    width: 40px;
    height: 40px;
  }
}
