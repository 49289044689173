.slider {
  position: relative;
  padding-bottom: 60px;
  //   &::before {
  //     position: absolute;
  //     content: '';
  //     top: 50%;
  //     left: 0;
  //     width: 100%;
  //     height: 100%;
  //     background-image: url('../../../assets/img/dust-4.png');
  //     background-repeat: no-repeat;
  //     background-size: contain;
  //     background-position: 0 0;
  //     z-index: -1;
  //   }
}
.slider__title {
  margin-bottom: 0;

  span {
    left: 0 !important;
  }
  div {
    left: 0 !important;
  }
}
.slider__arrows {
  display: flex;
  align-items: center;

  button:first-of-type {
    margin-right: 1em;
  }
}
.slider__wrapper {
  padding-bottom: 0;
}
.slider__component {
  & > div > div {
    height: 100%;
  }
}
.slider__component--outer-right {
  position: relative;
  overflow: hidden;
  padding-right: 0;

  .slick-list {
    width: 110%;
  }
}

@media (max-width: 1366px) {
  .slider__component--outer-right {
    .slick-list {
      width: 120%;
    }
  }
}
@media (max-width: 1280px) {
  .slider__component--outer-right {
    padding-right: 140px;

    .slick-list {
      width: 100%;
    }
  }
}
@media (max-width: 767px) {
  .slider .wrapper > .flex {
    .title {
      width: 70%;
    }
  }
  .slider__component--outer-right {
    padding-right: 40px;
  }
}
@media (max-width: 500px) {
  .slider {
    position: relative;
    padding-bottom: 45px;
  }
  .slider .wrapper > .flex {
    display: block !important;
    margin-bottom: 20px !important;

    .title {
      width: 100%;
    }
  }
  .slider__component--outer-right {
    padding-right: 20px;
  }
  .slider__arrows {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    justify-content: space-between !important;
    z-index: 100;
  }
}
