.footer {
  background-color: $d-gray;
}
.footer__wrapper {
  padding-top: 80px;
  padding-bottom: 80px;
}
.footer__column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  row-gap: 60px;
  width: 50%;
}
.footer__logo {
  margin-bottom: 60px;
  max-width: 92px;
}
.footer__nav {
  column-count: 2;
  column-gap: 5em;
  @include medium-text;

  a {
    display: block;
    margin-bottom: 2em;
    transition: ease 0.15s;

    &:hover {
      color: $orange;
    }
  }
}
.footer__copyright {
  font-size: 14px;
  opacity: 0.5;
}
.footer__socials {
  display: flex;

  li:not(:last-of-type) {
    margin-right: 15px;
  }
}
.footer__phone {
  a {
    display: block;
    @include big-text;
    margin-bottom: 15px;
    transition: ease 0.15s;

    &:hover {
      color: $orange;
    }
  }
  span {
    @include medium-text;
    opacity: 0.5;
  }
}
.footer__email {
  a {
    display: block;
    margin-top: 15px;
    font-weight: 500;
    font-size: 44px;
    transition: ease 0.15s;

    &:hover {
      color: $orange;
    }
  }
  span {
    @include medium-text;
    opacity: 0.5;
  }
}
.footer__address {
  @include medium-text;
}
.footer__policy {
  font-size: 14px;
  text-decoration: underline;
  color: $orange;
  transition: ease 0.15s;

  &:hover {
    color: #fff;
  }
}

@media (max-width: 1600px) {
  .footer__email {
    a {
      font-size: 42px;
    }
  }
}
@media (max-width: 1366px) {
  .footer__email {
    a {
      font-size: 40px;
    }
  }
}
@media (max-width: 1280px) {
  .footer__column {
    grid-row-gap: 45px;
  }
}
@media (max-width: 1024px) {
  .footer .flex {
    column-gap: 15px;
  }
  .footer__email {
    a {
      font-size: 30px;
    }
  }
}
@media (max-width: 767px) {
  .footer .flex {
    flex-direction: column;

    .footer__column:first-child {
      margin-bottom: 30px;
    }
  }
  .footer__column {
    width: 100%;
    grid-row-gap: 30px;
  }
  .footer__logo {
    margin-bottom: 30px;
  }
}
@media (max-width: 500px) {
  .footer__copyright,
  .footer__policy {
    font-size: 12px;
  }
  .footer__email {
    a {
      font-size: 24px;
    }
  }
}
