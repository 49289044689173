.athlete {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 12px;
}
.athlete__photo {
  margin-bottom: 20px;
  height: 100%;
  max-height: 400px;
  max-width: 400px;
  overflow: hidden;
  border-radius: 50%;
  background-color: $gray;

  img {
    width: 100%;
    height: 100%;
    transform: scale(1.1);
    object-fit: cover;
    object-position: top center;
  }
}
.athlete__name {
  display: block;
  margin-bottom: 13px;
  font-family: $formular-m;
  font-size: 20px;
  line-height: 120%;
  color: #f2f2f2;
}
.athlete__rank {
  font-family: $formular-r;
  font-size: 14px;
  line-height: 150%;
  opacity: 0.5;
}

@media (max-width: 1600px) {
  .athlete__photo {
    max-height: 360px;
    max-width: 360px;
  }
}
@media (max-width: 1365px) {
  .athlete__photo {
    max-height: 330px;
    max-width: 330px;
  }
}
@media (max-width: 1280px) {
  .athlete__photo {
    max-height: 300px;
    max-width: 300px;
  }
  .athlete__rank {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .athlete {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .athlete__rank {
    max-width: 70%;
    margin: 0 auto;
  }
}
@media (max-width: 500px) {
  .athlete__name {
    font-size: 16px;
  }
  .athlete__rank {
    max-width: 100%;
  }
  .athlete__photo {
    object-fit: cover;
  }
}
