.article {
  @include background-before('../../assets/img/bg-3.jpg', -10%, 100%);
  margin-top: 100px;
}
.article__title {
  width: 60%;
  font-size: 30px;
  line-height: 130%;
  color: #f2f2f2;
}
.article__category {
  margin-right: 35px;
}
.article__date {
  font-size: 14px;
  opacity: 0.4;
}
.article__content {
  margin-right: 2em;
  width: 70%;
  flex-shrink: 0;
}
.article__img {
  width: 100%;
  object-fit: cover;
}
.article__text {
  padding: 50px 0;
  @include text;
  border-bottom: 2px solid $gray;
}
.article__share {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;

  button {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    background-color: $orange !important;
    transition: ease 0.15s;

    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &:hover {
      background-color: #fff !important;

      .social-icon {
        background-color: $orange;
      }
    }
  }

  ul li:not(:last-child) {
    margin-right: 1em;
  }
}
.subscribe {
  display: flex;
  flex-direction: column;
  row-gap: 2em;
  padding: 3%;
  height: fit-content;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);

  ul li:not(:last-child) {
    margin-right: 1em;
  }
}
.subscribe__logo {
  width: 50%;
  object-fit: cover;
}
.subscribe__text {
  @include big-text;
  font-size: 22px;
  line-height: 150%;
  color: #f2f2f2;
}

@media (max-width: 1280px) {
  .article__content {
    margin-right: 1em;
  }
  .subscribe {
    padding: 3% 2%;
  }
}
@media (max-width: 1024px) {
  .article__container {
    & > .flex:nth-of-type(2) {
      flex-direction: column;
    }
  }
  .article__title {
    font-size: 26px;
    width: 100%;
  }
  .article__content {
    width: 100%;
  }
  .subscribe {
    max-width: 500px;
    margin: 0 auto;
    align-items: center;
  }
  .subscribe__logo {
    width: 100px;
  }
  .subscribe__text {
    max-width: 70%;
    text-align: justify;
  }
}
@media (max-width: 768px) {
  .article__title {
    font-size: 24px;
  }
}
@media (max-width: 500px) {
  .article {
    margin-top: 50px;
  }
  .article__title {
    font-size: 20px;
  }
  .subscribe {
    padding: 10% 15%;
  }
  .subscribe__text {
    max-width: 100%;
  }
}
