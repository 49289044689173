.card {
  position: relative;
  padding: 25px;
  display: flex;
  flex-direction: column;
  background-color: $gray;

  span {
    display: inline-block;
    &:not(:last-of-type) {
      margin-bottom: 45px;
    }
  }

  &--hit {
    background-color: $orange;

    .card__period,
    .card__info {
      color: #fff;
    }
  }
  &--hit::before {
    position: absolute;
    content: 'хит';
    top: -2px;
    right: 10%;
    padding: 30px 15px 50px 15px;
    @include big-text;
    line-height: 120%;
    font-weight: bold;
    text-align: center;
    color: $orange;
    background: linear-gradient(
          -30deg,
          transparent 19%,
          rgb(236, 236, 236) 20%,
          #fff 21%
        )
        right bottom 100%/50% no-repeat,
      linear-gradient(30deg, transparent 19%, rgb(236, 236, 236) 20%, #fff 21%)
        left bottom 100%/50% no-repeat;
    filter: drop-shadow(0 2px 4px rgb(94, 94, 94));
  }
}
.card__period,
.card__info {
  @include text;
  color: $l-gray-2;
}
.card__cost {
  font-family: $druk;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;

  b {
    margin-right: 0.2em;
    font-size: 38px;
  }
}
