@import './RecordForm/RecordForm.scss';

.record {
  background-image: url('../../assets/img/dust.png');
  background-size: 60%;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.record__block {
  justify-content: space-between;
  p {
    @include medium-text;
  }
  .align-center .title {
    margin-right: 2em;
  }
}
.record__title {
  span {
    white-space: nowrap;
  }
  span:nth-of-type(2) {
    left: 25%;
  }
  span:nth-of-type(3) {
    left: 10%;
  }
}
.record__block.flex {
  & > div:first-of-type {
    margin-right: 2em;
  }
}

@media (max-width: 1280px) {
  .record {
    background-position: center top;
    background-attachment: unset;
  }
}
@media (max-width: 1023px) {
  .record__block {
    display: block !important;

    & > div {
      margin-bottom: 60px;
    }
  }
  .record__form {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .record__title {
    span:nth-of-type(2) {
      left: 15%;
    }
    span:nth-of-type(3) {
      left: 10%;
    }
  }
}
@media (max-width: 500px) {
  .record__block {
    .title {
      margin-bottom: 1em;
    }
    & > div {
      margin-bottom: 30px;
    }
  }
}
