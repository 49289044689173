.schedule {
  @include background-before('../../assets/img/bg-2.jpg', -90%, 200%);
  min-height: 100vh;

  .select__label {
    span,
    input {
      font-size: 16px;
    }
  }
}
.schedule__container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  grid-gap: 30px;
  z-index: 2;

  li {
    padding: 3em;
    background-color: rgba(255, 255, 255, 0.1);
  }
}
.schedule__time {
  display: inline-block;
  margin-bottom: 3em;
  @include big-text;
  font-weight: bold;
  line-height: 100%;
  color: #f2f2f2;
}
.schedule__name {
  display: inline-block;
  margin-bottom: 5px;
  @include big-text;
  line-height: 100%;
  color: #fff;

  &--blue {
    color: #398fbf;
  }
  &--purple {
    color: #bf39aa;
  }
  &--orange {
    color: $orange;
  }
  &--green {
    color: #38aa16;
  }
  &--red {
    color: #a60c13;
  }
  &--lime {
    color: #a8bf12;
  }
}
.schedule__descrition {
  font-family: $formular-r;
  font-size: 14px;
  line-height: 160%;
  opacity: 0.5;
}

@media (max-width: 1600px) {
  .schedule__container {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 20px;

    li {
      padding: 2.5em;
    }
  }
}
@media (max-width: 1366px) {
  .schedule {
    .select__label {
      span,
      input {
        font-size: 14px;
      }
    }
  }
  .schedule__container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 15px;

    li {
      padding: 2em;
    }
  }
  .schedule__descrition {
    line-height: 150%;
  }
}
@media (max-width: 767px) {
  .schedule .wrapper > .flex {
    display: block !important;
    margin-bottom: 1em !important;

    .title {
      margin-bottom: 1em;
    }
    .select {
      width: 50%;
      width: fit-content;
    }
  }
}
@media (max-width: 500px) {
  .schedule__container {
    li {
      padding: 25px 15px;
    }
  }
  .schedule__descrition {
    font-size: 12px;
  }
}
