.reviews {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.reviews__list {
  align-self: flex-start;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 15px;
  margin-bottom: 5em;

  &--two-columns {
    grid-template-columns: 1fr 1fr;
  }
}
.review {
  &:not(:last-of-type) {
    margin-bottom: 3em;
  }
  p {
    @include text;
    font-size: 14px;
    color: $l-gray-2;
  }
}
.review__top {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}
.review__photo {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-right: 2em;
}
.review__author {
  display: block;
  margin-bottom: 0.5em;
  font-family: $formular-m;
  font-size: 20px;
  line-height: 120%;
  color: #f2f2f2;
}

@media (max-width: 1600px) {
  .reviews__list {
    margin-bottom: 2em;
  }
  .review__photo {
    margin-right: 2em;
  }
  .review__photo {
    max-width: 120px;
    max-height: 120px;
  }
}
@media (max-width: 1366px) {
  .review__photo {
    max-width: 110px;
    max-height: 110px;
  }
  .review__author {
    font-size: 18px;
  }
}
@media (max-width: 1280px) {
  .reviews__list {
    grid-column-gap: 20px;
  }
}
@media (max-width: 1024px) {
  .reviews__list {
    display: block;
  }
  .review__author {
    font-size: 16px;
  }
}
@media (max-width: 500px) {
  .review p {
    line-height: 140%;
  }
  .review__grade svg {
    width: 15px;
    height: 15px;
  }
  .review__photo {
    margin-right: 15px;
    width: 70px;
    height: 70px;
  }
  .review__author {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 14px;
  }
}
