// ==== Шрифты ====
$druk: 'Druk Wide Medium', sans-serif;
$formular-m: 'Formular-Medium', sans-serif;
$formular-r: 'Formular-Regular', sans-serif;

// ==== Цвета ====
$orange: #f15c22;
$l-gray: #ffffff1a;
$l-gray-2: #8e9091;
$gray: #2a2e30;
$d-gray: #131719;

// ==== Изображения ====
$bg1: '../img/bg-1.png';
$bg2: '../img/bg-2.jpg';
$bg3: '../img/bg-3.jpg';
$dust1: '../img/dust.png';
$dust2: '../img/dust-2.png';
$dust3: '../img/dust-3.png';
$dust4: '../img/dust-4.png';
$dust5: '../img/dust-5.png';
