.cards:nth-of-type(1) {
  margin-top: 100px;
}
.cards--bg {
  @include background-before('../../assets/img/bg-1.png', -100%, 220%);
}
.cards__footnote {
  @include text;
  color: $l-gray-2;
  width: 40%;
}
.cards__top {
  display: flex;
  justify-content: space-between;

  .title {
    white-space: nowrap;
  }
}
.cards__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(390px, 1fr));
  grid-gap: 30px;
  margin-bottom: 60px;
}
.cards__description {
  display: flex;
  justify-content: space-between;

  p {
    @include text;
    width: 40%;
  }
  button {
    width: fit-content;
  }
}

@media (max-width: 1600px) {
  .cards__description,
  .cards__top,
  .cards__list {
    grid-gap: 20px;
  }
  .cards__description button {
    grid-column-start: 2;
  }
  .cards__footnote {
    grid-column-start: 2;
  }
}
@media (max-width: 870px) {
  .cards__top,
  .cards__description {
    display: block;
  }
  .cards__footnote {
    width: 100%;
  }
  .cards__description p {
    width: 100%;
  }
}
@media (max-width: 1366px) {
  .cards__list {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 15px;
  }
}
@media (max-width: 1023px) {
  .cards__top .title {
    margin-bottom: 15px;
  }
  .cards__description p {
    margin-bottom: 30px;
  }
}
