.record-button {
  display: flex;
  align-items: center;
  padding: 0;
  width: fit-content;
  cursor: pointer;

  & * {
    transition: ease 0.15s;
  }
  &:hover * {
    color: #fff;
    fill: #fff;
  }
  span {
    display: inline-block;
    @include text;
    font-family: $druk;
    text-transform: uppercase;
    color: $orange;
    margin-right: 15px;
    font-size: 34px;
  }
}
