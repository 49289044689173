.merch {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;
  margin: 0 12px;
  max-height: 630px;
  background-color: $gray;

  b {
    margin-bottom: 5px;
    @include big-text;
    font-family: $formular-m;
    line-height: 110%;
    color: #f2f2f2;
  }
  span {
    margin-bottom: 35px;
    @include medium-text;
    line-height: 110%;
    opacity: 0.5;
  }
  & > div {
    width: 100%;
    margin-top: auto;
  }
}
.merch__photo {
  margin-bottom: 35px;
  max-height: 300px;
  height: 100%;
  object-fit: cover;
}
.merch__sizes {
  display: flex;
  margin-bottom: 30px;

  li:not(:last-of-type) {
    margin-right: 5px;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-size: 15px;
    line-height: 100%;
    border-radius: 50%;
    border: 1px solid $l-gray;
    background: $l-gray;
    cursor: pointer;
    transition: ease 0.25s;

    &:hover {
      border: 1px solid $orange;
    }
  }
}

@media (max-width: 1600px) {
  .merch {
    padding: 30px;
  }
  .merch__photo {
    max-height: 260px;
  }
}
@media (max-width: 1366px) {
  .merch {
    padding: 25px;
  }
  .merch__photo {
    margin-bottom: 20px;
    max-height: 220px;
  }
}
@media (max-width: 1366px) {
  .merch {
    padding: 20px;
  }
  .merch__photo {
    max-height: 200px;
  }
}
@media (max-width: 767px) {
  .merch {
    margin: 0 2px;
  }
}
