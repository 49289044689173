.location {
  background-image: url('../../assets/img/bg-3.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.location__address {
  display: flex;
  align-items: center;

  img {
    margin-right: 15px;
  }
  span {
    padding: 12px 20px;
    @include text;
    border: 1px solid $orange;
    border-radius: 40px;
  }
}
.location__map {
  margin-bottom: 5em;
  height: 500px;
  background-image: url('../../assets/img/map.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
}

@media (max-width: 1600px) {
  .location__map {
    margin-bottom: 3em;
    height: 450px;
  }
}
@media (max-width: 1366px) {
  .location__map {
    height: 400px;
  }
  .location__address {
    img {
      max-width: 40px;
      max-height: 40px;
    }
    span {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}
@media (max-width: 1280px) {
  .location .flex {
    display: block !important;

    .title {
      margin-bottom: 30px;
    }
  }
  .location__map {
    height: 300px;
  }
}
@media (max-width: 500px) {
  .location__address {
    img {
      max-width: 32px;
    }
    span {
      padding: 1em;
      font-size: 10px;
    }
  }
}
