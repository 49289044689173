.why {
  margin-bottom: 5em;
  @include background-before('../../assets/img/bg-1.jpg', -70%, 200%);
}
.why__flex {
  display: flex;
  flex-direction: column;

  div {
    width: 50%;
    margin-left: auto;
  }
  p {
    @include medium-text;
  }
}
.why__title {
  margin: 0;
  width: 50%;

  span:nth-of-type(3) {
    left: 50%;
  }
}
.why__features {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(7, 1fr);
  grid-column-gap: 30px;

  li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 10%;
    background-color: $orange;

    span {
      margin-bottom: 18px;
      font-family: $druk;
      font-size: 34px;
      font-weight: bold;
      line-height: 140%;
    }
    p {
      margin: 0;
      @include text;
      line-height: 140%;
    }
  }
  li {
    position: relative;
  }
  li:nth-of-type(1) {
    grid-row: 1 / 5;
    grid-column-start: 1;
  }
  li:nth-of-type(2) {
    grid-row: 2 / 6;
    grid-column-start: 2;
  }
  li:nth-of-type(3) {
    grid-row: 3 / 7;
    grid-column-start: 3;
  }
  li:nth-of-type(4) {
    grid-row: 4 / 8;
    grid-column-start: 4;
  }
}

@media (max-width: 1600px) {
  .why {
    margin-bottom: 3em;
  }
  .why__features {
    grid-template-rows: repeat(7, 1fr);
    grid-column-gap: 20px;

    li:nth-of-type(1) {
      grid-row: 1 / 4;
      grid-column-start: 1;
    }
    li:nth-of-type(2) {
      grid-row: 2 / 5;
      grid-column-start: 2;
    }
    li:nth-of-type(3) {
      grid-row: 3 / 6;
      grid-column-start: 3;
    }
    li:nth-of-type(4) {
      grid-row: 4 / 7;
      grid-column-start: 4;
    }
  }
}
@media (max-width: 1366px) {
  .why {
    margin-bottom: 1em;
  }
}
@media (max-width: 1024px) {
  .why__features {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(19, 20px);

    li:nth-of-type(1) {
      grid-row: 1 / 9;
      grid-column-start: 1;
    }
    li:nth-of-type(2) {
      grid-row: 3 / 11;
      grid-column-start: 2;
    }
    li:nth-of-type(3) {
      grid-row: 10 / 18;
      grid-column-start: 1;
    }
    li:nth-of-type(4) {
      grid-row: 12 / 20;
      grid-column-start: 2;
    }
  }
}
@media (max-width: 768px) {
  .why__title {
    span:nth-of-type(3) {
      left: 40%;
    }
  }
}
@media (max-width: 500px) {
  .why__flex {
    div {
      margin-bottom: 1em;
      margin-left: 0;
      width: 100%;
    }
    p {
      margin-bottom: 20px !important;
    }
  }
  .why__title {
    margin-bottom: 1em;
    width: 100%;

    span:nth-of-type(2) {
      left: 10%;
    }
    span:nth-of-type(3) {
      left: 30%;
    }
  }
  .why__features {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    li span {
      font-size: 21px;
    }
  }
}
