.promo__slider {
  .slide {
    min-height: 100vh;
    // min-height: calc(var(--vh, 1vh) * 100);
  }
  .slide__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 15% 0;
    padding-left: 35%;
    padding-right: 35%;

    p {
      @include text;
      position: relative;
      padding: 20px;
      font-size: 18px;
    }
    p::before {
      position: absolute;
      content: '+';
      top: 0;
      left: 0;
      font-size: 30px;
      color: $orange;
    }
  }
  .slide__title {
    // width: 30%;
  }
}
.promo__bottom {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 50px 60px 50px 35%;

  div:nth-of-type(2) {
    display: flex;
    justify-content: space-between;
    width: 100%;

    div {
      display: flex;
      align-items: center;
    }
  }
}
.promo__mouse {
  position: absolute;
  left: 50px;
  display: flex;
  align-items: center;

  object {
    margin-right: 30px;
    animation-iteration-count: infinite;
  }
  span {
    @include text;
  }
}
.promo__schedule {
  @include big-text;
  position: relative;
  transition: ease 0.15s;
  cursor: pointer;

  &::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #fff;
  }

  &:hover {
    color: $orange;
  }
}
.promo__button-modal {
  @include text;
  padding: 0;
  line-height: 100%;
  white-space: nowrap;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #fff;
  cursor: pointer;
  transition: ease 0.15s;

  &:hover {
    color: $orange;
  }
}
.promo__button-close {
  position: absolute;
  top: -20px;
  right: -20px;
  font-size: 0;
  cursor: pointer;

  &::after,
  &::before {
    position: absolute;
    content: '';
    width: 35px;
    height: 2px;
    background-color: #fff;
  }
  &::after {
    transform: rotate(45deg);
  }
  &::before {
    transform: rotate(-45deg);
  }
}
.promo__arrows {
  margin-right: 30px;

  button:not(:last-of-type) {
    margin-right: 15px;
  }
}
.promo__arrow {
  cursor: pointer;

  svg {
    vertical-align: middle;
  }
  svg path {
    transition: ease 0.15s;
  }
  &:hover svg path {
    fill: $orange;
  }
}
.promo__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: -1;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

// @media (max-width: 1366px) {
//   .promo__slider {
//     .slide__title {
//       width: 100%;
//     }
//   }
// }
@media (max-width: 1280px) {
  .promo__slider {
    .slide__content {
      padding-left: 25%;
      background-position: right;
    }
  }
  .promo__bottom {
    padding-left: 25%;
  }
  .promo__mouse {
    display: none;
  }
}
@media (max-width: 1024px) {
  .promo__slider {
    .slide__content {
      background-position: 80%;
    }
  }
}
@media (max-width: 768px) {
  .promo__slider {
    .slide__content {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 45px;
      padding-right: 45px;
    }
    p {
      width: 70%;
    }
  }
  .promo__image {
    img {
      object-fit: cover;
      object-position: center;
      height: 100%;
    }
  }
  .promo__schedule {
    display: none;
  }
  .promo__bottom {
    padding: 45px;
  }
  .promo__button-close {
    top: 30px;
    right: 50px;
  }
}
@media (max-width: 500px) {
  .promo__slider {
    .slide {
      min-height: 500px;
    }
    .slide__content {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 20px;
      padding-right: 20px;

      p {
        width: 100%;
      }
    }
  }
  .promo__image {
    img {
      object-fit: cover;
      object-position: right;
      height: 100%;
    }
  }
  .promo__bottom {
    padding: 45px 20px;
  }
}
