.video-report {
  @include background-before('../../assets/img/bg-2.jpg');

  .video {
    margin-bottom: 200px;
  }
}

@media (max-width: 500px) {
  .video-report {
    .video {
      margin-bottom: 60px;
    }
  }
}
