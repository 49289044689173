.record__form {
  display: flex;
  flex-direction: column;
  width: 40%;
  flex-shrink: 0;
}
@media (max-width: 1280px) {
  .record__form {
    width: 50%;
  }
}
