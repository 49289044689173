.slider-arrow {
  padding: 0;
  text-align: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: $gray;
  cursor: pointer;
  transition: ease 0.15s;

  &:hover {
    filter: brightness(1.3);
  }
  &--orange {
    background-color: $orange;
  }
  svg {
    vertical-align: middle;
  }
}

@media (max-width: 500px) {
  .slider-arrow {
    width: 30px;
    height: 30px;
    background-color: #fff;

    svg {
      width: 12px;

      path {
        fill: $orange;
      }
    }
  }
}
