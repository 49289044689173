.founder {
  video {
    width: 100%;
  }
}
.founder__wrapper {
  padding-right: 0;
}
.founder__name {
  display: block;
  font-family: $formular-m;
  font-size: 22px;
  line-height: 120%;
  color: #f2f2f2;
}
.founder__post {
  display: block;
  margin-bottom: 20px;
  font-family: $formular-m;
  font-size: 18px;
  line-height: 120%;
  color: $orange;
}
.founder__cite {
  @include text;
  text-align: justify;
  color: #f2f2f2;
}
.founder__container {
  display: flex;
  align-items: center;
  margin-top: 100px;
}
.founder__photo {
  position: relative;
  width: 50%;
  padding-right: 5em;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 1024px) {
  .founder__photo {
    width: 50%;
    padding: 0;
  }
  .founder__cite {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 768px) {
  .founder__container {
    flex-direction: column;
  }
  .founder__photo {
    width: 70%;
  }
}
@media (max-width: 500px) {
  .founder__container {
    margin-top: 50px;
  }
  .founder__name {
    font-size: 14px;
    line-height: 150%;
  }
  .founder__post {
    font-size: 12px;
  }
}
