.video {
  position: relative;
  margin-bottom: 60px;
  max-height: 90vh;

  img {
    width: 100%;
  }
}
.modal-video {
  background-color: rgba(0, 0, 0, 0.7);
}
.video__play-button {
  position: absolute;
  left: calc(50% - 152px / 2);
  bottom: -73px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    padding: 0;
    margin-right: 30px;
    width: 152px;
    height: 152px;
    cursor: pointer;
    transition: ease 0.5s;

    circle,
    path {
      transition: ease-out 0.3s;
      transform-origin: center;
    }
  }
  span {
    font-family: $druk;
    font-size: 22px;
    line-height: 140%;
    text-transform: uppercase;
  }
  button:hover {
    filter: drop-shadow(0 0 6px $orange);

    #inside {
      fill: #fff;
      r: 40%;
    }
    #outside {
      stroke: #fff;
      r: 40%;
    }
    path {
      transform: scale(1.4);
      fill: $orange;
    }
  }
}

@media (max-width: 1366px) {
  .video__play-button span {
    font-size: 20px;
  }
}
@media (max-width: 1280px) {
  .video__play-button span {
    font-size: 18px;
  }
  .modal-video-body {
    max-width: 700px;
  }
}
@media (max-width: 1024px) {
  .video__play-button {
    left: calc(50% - 120px / 2);
    bottom: -57px;

    span {
      font-size: 16px;
    }
    button {
      width: 120px;
      height: 120px;
    }
  }
}
@media (max-width: 767px) {
  .video__play-button {
    left: calc(50% - 200px / 2);
    bottom: -47px;

    span {
      font-size: 14px;
    }
    button {
      width: 100px;
      height: 100px;
    }
  }
}
@media (max-width: 500px) {
  .video__play-button {
    left: calc(50% - 200px / 2);
    bottom: -33px;

    span {
      font-size: 12px;
    }
    button {
      width: 70px;
      height: 70px;
    }
  }
}
