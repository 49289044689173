.input {
  display: flex;
  align-items: center;
  @include text;
  padding: 13px 27px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 40px;

  span {
    margin-right: 5%;
    flex-shrink: 0;
  }
  input {
    padding: 0;
    flex-grow: 1;
    color: $l-gray-2;
    background-color: transparent;
    border: none;
  }
  input:focus,
  input:active {
    color: #fff;
    outline: none;
  }
  &--focused {
    border-color: $orange;
  }
}
