.about {
  background-position: 0 -40px;
  background-repeat: no-repeat;
  background-size: 100%;

  .flex > div {
    width: 50%;
  }
  &::after {
    position: absolute;
    content: url("../../assets/img/dust-5.png");
    right: 0;
    bottom: -50%;
    z-index: -1;
  }
}
.about__image {
  margin-right: 5%;
  img {
    max-height: 700px;
  }
}
.about__text {
  @include medium-text;
}
.about__list {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  row-gap: 1.5em;

  li {
    @include big-text;
    font-weight: bold;
    line-height: 140%;
    color: $orange;
    text-transform: uppercase;
  }
}

@media (max-width: 1279px) {
  .about__image {
    margin-bottom: 2rem;
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      max-height: 600px;
      object-fit: cover;
      object-position: 0 70%;
    }
    & + div {
      width: 100% !important;
    }
  }
  .about__list li:nth-child(even) {
    text-align: right;
  }
}
@media (max-width: 1024px) {
  .about__list {
    grid-template-columns: 1fr;

    li {
      text-align: left !important;
    }
  }
}
@media (max-width: 768px) {
  .about .flex {
    flex-direction: column;
  }
  .about__image {
    margin-right: 0;
  }
  .about__list {
    grid-template-columns: 1fr 1fr;
  }
}
