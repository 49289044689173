.features-blocks {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-column-gap: 30px;
  margin-top: 90px;

  li {
    padding: 40px 60px;
    background-color: $gray;

    span:nth-of-type(1) {
      display: block;
      font-family: $druk;
      font-size: 20px;
      font-weight: bold;
      line-height: 140%;
      color: $orange;
      text-transform: uppercase;
    }
    span:nth-of-type(2) {
      @include text;
    }
  }
}

@media (max-width: 1600px) {
  .features-blocks {
    li {
      padding: 30px 40px;
    }
  }
}
@media (max-width: 1366px) {
  .features-blocks {
    li {
      padding: 20px 40px;
    }
  }
}
@media (max-width: 1280px) {
  .features-blocks {
    margin-top: 50px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;

    li {
      padding: 20px 25px;
      min-height: 115px;
    }
    li:last-child {
      transform: translateX(50%);
    }
  }
}
@media (max-width: 767px) {
  .features-blocks {
    grid-template-columns: 1fr;

    li:last-child {
      transform: translateX(0);
    }
  }
}
