$padding: 2em;

.faq__list {
  & > li {
    border-top: 2px solid $gray;
    transition: ease 0.25s;
  }
  & > li:hover {
    box-shadow: inset 0 0 25px 5px #222527;
  }
  & > li:last-of-type {
    border-bottom: 2px solid $gray;
  }
}
.faq__question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 20px;
  width: 100%;
  cursor: pointer;

  span {
    display: inline-block;
    margin-right: 5%;
    @include big-text;
    font-size: 22px;
    text-align: left;
    line-height: 120%;
    font-weight: 500;
  }
  .active {
    color: $orange;
  }
}
.faq__answer {
  max-height: 0;
  padding: 0 20px;
  overflow: hidden;
  transition: max-height 0.25s ease-out;

  * {
    margin: 0;
    @include text;
    color: #fff !important;
  }
  ul,
  p {
    padding-bottom: $padding;
    text-align: justify;
  }
  a {
    text-decoration: underline;
    color: $orange;
  }
  ul,
  li {
    border: none !important;
  }
  ul {
    padding-left: 15px;
  }
  li:not(:last-child) {
    margin-bottom: 1em;
  }
}
.faq__answer--opened {
  display: block;
}
.faq__arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: $gray;
  transition: ease 0.15s;

  &:hover {
    filter: brightness(1.3);
  }
  &--rotated {
    transform: rotate(90deg);
    background-color: $orange;
  }
}

@media (max-width: 1279px) {
  .faq__question {
    @include medium-text;
    // padding-top: 1.25em;
    // padding-bottom: 1.25em;
  }
}
@media (max-width: 500px) {
  .faq__arrow {
    width: 30px;
    height: 30px;

    svg {
      width: 12px;
    }
  }
  .faq__answer p {
    text-align: justify;
  }
}
@media (max-width: 350px) {
  .faq__question span {
    font-size: 14px;
  }
}
