.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 99999;
}
.modal {
  position: absolute;
  padding: 80px 100px;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 70vw;
  border-radius: 30px;
  border: none;
  background-color: #131718;
  z-index: 999999;

  .title {
    width: 50%;
  }
  .form__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2em;
    grid-row-gap: 2em;

    textarea {
      grid-row-start: 1;
      grid-row-end: 5;
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }
}
.modal__text {
  width: 50%;
  @include text;

  span {
    color: $orange;
  }
}

.modal__close {
  position: absolute;
  top: -20px;
  right: -20px;
  font-size: 0;
  cursor: pointer;

  &::after,
  &::before {
    position: absolute;
    content: '';
    width: 35px;
    height: 2px;
    background-color: #fff;
  }
  &::after {
    transform: rotate(45deg);
  }
  &::before {
    transform: rotate(-45deg);
  }
}

@media (max-width: 1600px) {
  .modal {
    padding: 80px 60px;
  }
}
@media (max-width: 1366px) {
  .modal {
    padding: 60px 60px;
    max-width: 80vw;
  }
}
@media (max-width: 1024px) {
  .modal {
    padding: 60px 40px;
    max-width: 80vw;

    .align-center {
      display: block !important;

      .title {
        margin-bottom: 20px;
        width: 100%;
      }
      .modal__text {
        margin-bottom: 20px;
        width: 100%;
      }
    }
    .form > .flex {
      display: block !important;
    }
    .form__container {
      grid-template-columns: 1fr;

      textarea {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 4;
        grid-row-end: 10;
      }
    }
  }
}
@media (max-width: 768px) {
  .modal {
    padding: 40px 20px;
    max-width: 90vw;

    .form__container {
      grid-row-gap: 1em;

      textarea {
        grid-row-start: 4;
        grid-row-end: 12;
      }
    }
    p {
      font-size: 12px;
    }
  }
}
@media (max-width: 500px) {
  .modal {
    padding: 40px 10px;
    max-width: 100vw;

    .input,
    .select {
      padding-left: 1em;
    }
  }
}
