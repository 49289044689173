.social-icon {
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;

  &--vk {
    mask: url('../../../assets/icons/vk.svg') center no-repeat;
  }
  &--instagram {
    mask: url('../../../assets/icons/instagram.svg') center no-repeat;
  }
  &--youtube {
    mask: url('../../../assets/icons/youtube.svg') center no-repeat;
  }
  &:hover {
    background-color: $orange;
  }
}

@media (max-width: 1366px) {
  .social-icon {
    mask-size: 60%;
  }
}
