.preview {
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.1);

  figcaption {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 40px;

    .flex {
      margin-top: auto;
    }
  }
}
.preview__title {
  display: inline-block;
  margin-bottom: 35px;
  @include big-text;
  font-size: 22px;
  line-height: 130%;
  color: #f2f2f2;
}
.preview__link {
  position: relative;
  font-size: 14px;
  color: $orange;
  transition: ease 0.15s;

  &::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    background-color: $orange;
  }
  &:hover {
    color: #fff;
  }
}
.preview__date {
  font-size: 14px;
  opacity: 0.5;
}

@media (max-width: 400px) {
  .preview figcaption {
    padding: 25px 20px;
  }
}
