.form {
  position: relative;
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 30px;
  }
}
.error {
  margin-bottom: 15px !important;
  font-family: $formular-r;
  font-size: 14px !important;
  color: rgb(192, 7, 7);
}
@media (max-width: 500px) {
  .error {
    font-size: 12px !important;
  }
}
