.discount .flex {
  border-bottom: 2px solid $l-gray;
}
.discount__title {
  span {
    position: relative;
    line-height: 130%;
    white-space: nowrap;
  }
  span:nth-of-type(2) {
    left: 25%;
    color: $orange;
  }
}
.discount__for {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 30px;

  li {
    @include text;
    text-align: center;
    line-height: 150%;
    color: #f2f2f2;
  }
}
.discount__image {
  max-width: 40%;
}
@media (max-width: 800px) {
  .discount .flex {
    position: relative;
  }
  .discount__image {
    position: absolute;
    right: 0;
    flex-shrink: 0;
    width: 50%;
    z-index: -1;
  }
}
@media (max-width: 620px) {
  .discount__image {
    // top: -25%;
    opacity: 0.4;
    max-width: 100%;
  }
}
