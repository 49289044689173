// ==== Глобальные стили ====
body {
  height: 100%;
  background-color: $d-gray;
  overflow-x: hidden;

  * {
    font-family: $formular-r;
    box-sizing: border-box;
    color: #fff;
  }
}
// html {
//   scroll-behavior: smooth;
// }
section,
footer {
  position: relative;
  z-index: 1;
}
header,
main {
  flex: 1 0 auto;
}
footer {
  margin-top: auto;
  flex: 0 0 auto;
}
img {
  max-width: 100%;
  height: auto;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  text-decoration: none;
}
button {
  border: none;
  background-color: transparent;
}
p,
figure {
  margin: 0;
}
fieldset {
  border: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #131718 inset !important;
  box-shadow: 0 0 0 30px #131718 inset !important;
  -webkit-text-fill-color: $l-gray-2 !important;
  -webkit-padding-start: 0 !important;
}

// ==== Общие классы ====
#root {
  height: 100%;
}
.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}
.category {
  display: block;
  padding: 5px 10px;
  margin-bottom: 15px;
  width: fit-content;
  font-size: 14px;
  font-weight: normal;
  text-transform: lowercase;
  line-height: 160%;
  border-radius: 40px;
  background-color: $orange;
}
.title {
  margin: 0;
  margin-bottom: 45px;
  font-family: $druk;
  font-size: 44px;
  line-height: 100%;
  text-transform: uppercase;

  span {
    display: block;
  }
}
.subtitle {
  margin: 0;
  font-family: $druk;
  font-size: 26px;
  line-height: 140%;
  text-transform: uppercase;
}
.wrapper {
  padding: 80px 260px;
}
.wrapper-w100 {
  padding: 80px 0;
}
.wrapper-w100-pl {
  padding: 80px 0 80px 260px;
}
.pl {
  padding-left: 260px;
}
.pr {
  padding-right: 260px;
}
.slick-track {
  display: grid;
  grid-auto-columns: auto;
  grid-auto-flow: column;

  .slick-slide > div,
  .slick-slide > div > div {
    height: 100% !important;
  }
}
.flex {
  display: flex !important;

  * {
    margin: 0;
  }
  .title {
    margin: 0;
  }
}
.row {
  flex-direction: row !important;
}
.column {
  flex-direction: column !important;
}
.space-between {
  justify-content: space-between !important;
}
.space-around {
  justify-content: space-around !important;
}
.align-center {
  align-items: center !important;
}
.mb1 {
  margin-bottom: 1em !important;
}
.mb2 {
  margin-bottom: 2em !important;
}
.mb3 {
  margin-bottom: 3em !important;
}
.mb4 {
  margin-bottom: 4em !important;
}
.mb5 {
  margin-bottom: 5em !important;
}
.mt1 {
  margin-top: 1em !important;
}
.mt2 {
  margin-top: 2em !important;
}
.mt3 {
  margin-top: 3em !important;
}
.mt4 {
  margin-top: 4em !important;
}
.mt5 {
  margin-top: 5em !important;
}

@media (max-width: 1600px) {
  .wrapper {
    padding: 60px 230px;
  }
  .wrapper-w100 {
    padding: 60px 0;
  }
  .wrapper-w100-pl {
    padding: 60px 0 60px 230px;
  }
  .pl {
    padding-left: 230px;
  }
  .pr {
    padding-right: 230px;
  }
}
@media (max-width: 1366px) {
  .subtitle {
    font-size: 24px;
  }
  .wrapper {
    padding: 60px 200px;
  }
  .wrapper-w100 {
    padding: 60px 0;
  }
  .wrapper-w100-pl {
    padding: 60px 0 60px 200px;
  }
  .pl {
    padding-left: 200px;
  }
  .pr {
    padding-right: 200px;
  }
}
@media (max-width: 1280px) {
  .subtitle {
    font-size: 22px;
  }
  .wrapper {
    padding: 60px 140px;
  }
  .wrapper-w100 {
    padding: 60px 0;
  }
  .wrapper-w100-pl {
    padding: 60px 0 60px 140px;
  }
  .pl {
    padding-left: 140px;
  }
  .pr {
    padding-right: 140px;
  }
  .mb1 {
    margin-bottom: 0.5em !important;
  }
  .mb2 {
    margin-bottom: 1em !important;
  }
  .mb3 {
    margin-bottom: 2em !important;
  }
  .mb4 {
    margin-bottom: 3em !important;
  }
  .mb5 {
    margin-bottom: 3em !important;
  }
}
@media (max-width: 767px) {
  .subtitle {
    font-size: 20px;
  }
  .wrapper {
    padding: 60px 40px;
  }
  .wrapper-w100 {
    padding: 60px 0;
  }
  .wrapper-w100-pl {
    padding: 60px 0 60px 40px;
  }
  .pl {
    padding-left: 40px;
  }
  .pr {
    padding-right: 40px;
  }
}
@media (max-width: 500px) {
  .subtitle {
    font-size: 16px;
  }
  .wrapper {
    padding: 45px 20px;
  }
  .wrapper-w100 {
    padding: 45px 0;
  }
  .wrapper-w100-pl {
    padding: 45px 0 45px 20px;
  }
  .pl {
    padding-left: 20px;
  }
  .pr {
    padding-right: 20px;
  }
}
